.password-reset-section {
  margin-left: 1em;
  margin-top: 1em;
}

.password-reset-section p {
  font-size: smaller;
}

.recover-password-btn {
  margin-top: 2em;
}
