.receipt-details > div > b {
  margin-right: 1rem;
}

.printed {
  display: none;
}
.printed .email {
  color: #385CAD;
}
@media print {
  .printed {
    display: block;
    border: solid 1px black;
    padding: 1rem;
    border-radius: 3px;
    margin-top: 10rem;
    margin-left: 10rem;
    margin-right: 5rem;
  }
}
