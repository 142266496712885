.checkout-panel {
  color: #BCC2CB;
}
.checkout-panel .panel-content {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: solid;
  border-width: 0px 1px 1px 1px;
  padding: 2rem;
  color: #273043 !important;
}
.checkout-panel .panel-header {
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  border-top-left-radius: 10px;
  background-color: #273043;
  padding: 0.75rem 0rem 0.75rem 0.75rem;
  color: white;
}
