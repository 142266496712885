.login-form {
  margin: auto;
}

.login-container {
  background-color: #EAEAEA;
}

.forgot-password-link {
  margin-top: 1em;
}

.login-page {
  padding: 2em 0em 2em 0em;
  max-width: 500px;
  margin: 0 auto;
}

.login-page button {
  margin: auto;
  margin-top: 1em;
}

.login-page a {
  color: #385CAD;
}
.login-page a:visited {
  color: #385CAD;
}

.login-alternative {
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  padding: 1em 0em 1em 0em;
  background-color: #EAEAEA;
}

.login-alternative-content label {
  margin-bottom: 1em;
}
.login-alternative-content button {
  margin-top: 1em;
}

.login-button {
  display: block;
  margin: auto;
  margin-top: 1em;
}

.signin-button-row {
  text-align: center;
}

.signin-button-row button {
  margin: auto;
  margin-top: 1.2em;
}

/*
.signin-button-row button:focus {
  outline: solid 1px $dark-steel-gray;
  border: solid 1px $medium-light-gray;
}
*/
