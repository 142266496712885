.examinee-upload-box {
  padding: 0rem 1rem 1rem 0rem;
}
.examinee-upload-box .download-link {
  margin: 1rem 1rem 1rem 0rem;
  border: solid 1px #385CAD;
  padding: 1rem;
  border-radius: 3px;
}
.examinee-upload-box .download-link .info-icon {
  color: #385CAD;
  margin-right: 1rem;
}
.examinee-upload-box .selected-file-status {
  padding: 0rem 0rem 0.5rem 2rem;
  font-weight: bold;
}
.examinee-upload-box .selected-file-status > input {
  color: #385CAD;
  width: 20rem;
}
.examinee-upload-box .selected-file-status > label {
  margin-right: 1rem;
}
