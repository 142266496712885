.enter-token-block {
  background-color: #EAEAEA;
  color: #273043;
}

.token-input-form {
  margin: auto;
  padding: 1.5em;
}

.token-input-form input,
.token-input-form button {
  width: 100%;
  margin-top: 1em;
}
