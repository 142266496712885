.validation-message {
  color: red;
  font-weight: bold;
  font-size: smaller;
  height: 1.5em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}
