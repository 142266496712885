.examinee-results-user-header {
  color: #273043;
}

.examinee-results-list {
  padding: 0.2em;
  max-height: 10em;
  overflow: auto;
}

.examinee-results-item:nth-child(odd) {
  background-color: #FFFFFF;
}

.examinee-results-item {
  padding: 0.3em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
