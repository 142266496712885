.override-password-requirement-message {
  padding-left: 11rem;
}

.override-button-bar {
  margin-top: 1rem;
}
.override-button-bar button {
  margin-left: 0.5rem;
}
