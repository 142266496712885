.eap-banner-background {
  color: #FFFFFF;
  background: url("../images/eap-banner-hi-res.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.eap-banner-menu-container {
  text-align: right;
}
.eap-banner-menu-container span, .eap-banner-menu-container a {
  color: #FFFFFF;
}
.eap-banner-menu-container a:hover, .eap-banner-menu-container a:hover span {
  color: #BCC2CB;
  background-color: transparent;
}

.eap-banner-text {
  padding-left: 0.5em;
}

.tagLine {
  padding: 1em;
  margin: 0px;
  display: flex;
  flex: 1;
  align-items: center;
}
.tagLine h1 {
  text-align: left;
}

.eap-public-banner {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 160px;
  text-align: center;
  background: url("../images/eap-banner-hi-res.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.eap-public-banner h1 {
  margin-bottom: 0px;
  color: #FFFFFF;
}
