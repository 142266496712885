.eap-form-header {
  text-align: center;
}
.eap-form-header h1 {
  text-align: center;
  color: #273043;
}
.eap-form-header p {
  text-align: left;
}
