.modal-body {
  border: 3px solid #273043;
  border-radius: 6px;
}

.modal-title {
  width: 100%;
  margin-left: 1em;
}

.modal-banner {
  margin-top: -0.1em;
  margin-left: -0.05em;
  margin-right: -0.05em;
  width: 103%;
  color: #FFFFFF;
  background-color: #273043;
}
