.checkout-container {
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.checkout-previous-button, .checkout-next-button {
  margin-top: 1rem;
}

.checkout-next-button {
  float: right;
}
