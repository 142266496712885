.eap-alert-area {
  margin: auto;
  text-align: center;
}

.eap-alert-box {
  text-align: center;
  min-height: 1em;
  padding: 0.7em;
  margin-bottom: 0.5em;
}

.eap-alert-info {
  border: 1px solid #273043;
  background-color: #8794CB;
  color: #273043;
}
.eap-alert-info a {
  color: #273043;
}
.eap-alert-info a:hover {
  color: #385CAD;
}

.eap-alert-cancel {
  padding-left: 0.5em;
}

.eap-alert-error {
  color: #b20000;
  background-color: #ff8c8c;
  border: 1px solid #b20000;
}
.eap-alert-error a {
  color: #b20000;
}
.eap-alert-error a:hover {
  color: #f70000;
}

.eap-alert-success {
  background-color: #65c665;
  border: 1px solid #008000;
  color: #008000;
}
.eap-alert-success a {
  color: #008000;
}
.eap-alert-success a:hover {
  color: #00bf00;
}
