.public-signup-form {
  display: flex;
  flex-wrap: wrap;
}
.public-signup-form > * {
  flex: 1 1 50%;
}

#public-terms-and-conditions {
  margin-top: 1.9rem;
}

.email-username-toggle {
  text-align: left;
  margin-top: 1.5rem;
  color: blue;
  text-decoration: underline;
  background-color: transparent;
  border: none;
}
