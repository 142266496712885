.no-route-content {
  margin-top: 1em;
  height: 15em;
  background-color: #EAEAEA;
  color: #273043;
}
.no-route-content p {
  margin-bottom: 1em;
  font-size: larger;
  text-align: center;
}
