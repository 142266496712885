.groupSelector #groupSelector {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
}
.groupSelector #error {
  color: #f70000;
}
.groupSelector #buttonContainer {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-evenly;
}
