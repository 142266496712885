.eap-alert-message {
  color: red;
  background-color: 1px solid red;
  border: 1px solid red;
  border-radius: 0.3em;
}

.eap-alert-happy {
  border: 1px solid green;
  color: green;
}

.eap-alert-warning {
  background-color: lightyellow;
  border: 1px solid red;
  color: red;
  font-weight: bolder;
}

.eap-static-alert {
  font-size: smaller;
  padding: 0.7em;
  margin-bottom: 0.5em;
}
