.add-group {
  margin-left: 1em;
}
.add-group label {
  margin-top: 1em;
}
.add-group input {
  width: 95%;
}
.add-group button {
  margin-top: 1em;
}
