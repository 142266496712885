.pipe-separator {
  color: #273043;
}

.eap-menu-bar-container {
  background-color: #BCC2CB;
  color: #FFFFFF;
}
.eap-menu-bar-container a {
  border: none;
  color: #273043;
  background-color: #BCC2CB;
}
.eap-menu-bar-container a:hover {
  color: #385CAD;
  background-color: #EAEAEA;
}
.eap-menu-bar-container a:focus {
  background-color: #EAEAEA;
  outline: 1px dotted #212121;
  color: #273043;
}
.eap-menu-bar-container a:active {
  background-color: #EAEAEA !important;
  color: #273043;
}
