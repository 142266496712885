.partner-banner-block {
  margin-top: 2em;
}

.partner-banner-block span {
  text-align: left;
  font-size: larger;
  font-weight: bolder;
}

.partner-banner-block img {
  margin-top: 1em;
  display: block;
  width: 50%;
}
