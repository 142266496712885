.apd-group-name {
  font-weight: bolder;
}

.add-product-element {
  padding: 0.5rem;
}

.add-product-element button, .add-product-element input {
  margin-top: 0.2rem;
}
