.styled-form-container {
  align-items: center;
  margin: auto;
}

.styled-input-form {
  display: flex;
  flex-wrap: wrap;
}
.styled-input-form > .public-email-username-form-group {
  flex: 2 1 100%;
}
.styled-input-form > .public-email-username-form-group > label {
  display: inline;
}
.styled-input-form > .public-email-username-form-group > button {
  margin-left: 2.5rem;
}
.styled-input-form > .public-email-username-form-group > input {
  width: 92.5%;
}
.styled-input-form > .form-input-group {
  flex: 1 1 50%;
}
