.products-inventory-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.products-inventory-form .pure-control-group {
  display: flex;
  justify-content: center;
  align-items: center;
}
