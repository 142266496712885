.eap-admin-menu a {
  color: #273043;
  background-color: #8E98A7;
}
.eap-admin-menu a:hover {
  background-color: #BCC2CB;
}
.eap-admin-menu a:focus {
  background-color: #EAEAEA;
}
.eap-admin-menu a:active {
  background-color: #EAEAEA;
}

.eap-menu-current {
  background-color: #EAEAEA !important;
}

.eap-menu-icon {
  width: 1.5em;
}

.eap-admin-menu-transparent a {
  color: #273043;
  border-bottom: 0.1em solid;
  border-color: #FFFFFF;
}
.eap-admin-menu-transparent a:hover {
  background-color: transparent;
  color: #385CAD;
}
.eap-admin-menu-transparent a:focus {
  background-color: transparent;
  outline: 1px dotted #212121;
  color: #273043;
}

.eap-menu-transparent-active {
  color: #273043;
  border-bottom: 0.1em solid;
  border-color: #FFFFFF;
}
.eap-menu-transparent-active:focus {
  background-color: transparent;
  color: #273043;
  outline: 1px dotted #212121;
}
