.add-administrator-form {
  padding: .5rem;
  .pure-u-1-4 {
    padding-left: 1rem;
  }
}

.selected-user-display {
  padding-bottom: 1rem;
  .selected-user-label {
    font-weight: bold;
    margin-right: .5rem;
  }
}
