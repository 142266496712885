#purchaseContainer {
  display: flex;
  flex: 1 1 auto;
}

.purchase .purchaseContent {
  display: inline-block;
  margin: auto;
}
.purchase .right {
  text-align: right;
}
.purchase table {
  border: 1px solid black;
  border-collapse: collapse;
}
.purchase td, .purchase th {
  padding: 0.5rem;
  border: 1px solid black;
  border-collapse: collapse;
}
