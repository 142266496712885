.wizard-step-indicator {
  text-align: center;
  margin: auto;
}
.wizard-step-indicator > img {
  width: 3rem;
  margin: auto;
}
.wizard-step-indicator > svg {
  background: transparent;
}
.wizard-step-indicator .display-text {
  width: 100%;
  color: #273043;
  margin: auto;
  margin-bottom: 1.5rem;
  font-size: 0.8rem;
}
.wizard-step-indicator .display-text-payment-failed {
  color: red !important;
}
.wizard-step-indicator .display-text-completed {
  color: #385CAD !important;
}
.wizard-step-indicator .display-text-todo {
  color: #BCC2CB !important;
}
