.form-input-group {
  padding-top: 0.75rem;
}
.form-input-group label {
  display: block;
}
.form-input-group.form-input-required > label:after {
  content: " *";
}
.form-input-group input[type=text], .form-input-group input[type=password], .form-input-group select {
  border-radius: 4px;
  width: 85%;
}
.form-input-group input[type=checkbox] {
  margin-right: 0.5rem;
}
.form-input-group select {
  margin-top: 0.5rem !important;
  min-height: 2.4rem !important;
}
.form-input-group > .react-datepicker-wrapper > .react-datepicker__input-container > input {
  border-radius: 4px;
  width: 85% !important;
}
