.group-editor-box label {
  margin-bottom: 0.2em;
}

.group-editor-box input[type=text] {
  width: 95%;
}

.group-editor-box button {
  margin-left: 0.5em;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
