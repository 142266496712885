@import url("https://fonts.googleapis.com/css?family=Roboto:regular,bold|Roboto+Slab:regular,bold");
html {
  font-size: 10pt;
}

.footer {
  width: 100%;
  text-align: center;
  padding-top: 1em;
}
.footer a {
  margin: 0em 1em 0em 1em;
  color: #385CAD;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}
.footer a:hover {
  color: #8794CB;
}

@media (min-width: 35.5em) {
  .footer-tos {
    text-align: right;
  }
}

@media (min-width: 35.5em) {
  .footer-feedback {
    text-align: left;
  }
}

.footer-copyright {
  font-family: "Roboto Slab", serif !important;
  padding-top: 0.5em;
  text-align: center;
  color: #FFFFFF;
  background-color: #273043;
  min-height: 2em;
  margin: 0px;
}
