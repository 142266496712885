.autoStart {
  background-color: #8794CB;
  width: 98%;
  margin: auto;
  margin-bottom: 1em;
}
.autoStart .left-column {
  min-height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.autoStart .left-column p {
  margin-left: 0.5em;
}
.autoStart .center-btn {
  text-align: center;
}
