.wizard-progress-bar {
  padding: 2rem 3rem 0rem 3rem;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
}
.wizard-progress-bar hr {
  margin-top: 1.5rem;
  display: inline;
  width: 100%;
}
