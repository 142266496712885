@import url("https://fonts.googleapis.com/css?family=Roboto:regular,bold|Roboto+Slab:regular,bold");
html {
  font-size: 10pt;
}

.flex-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.flex-column {
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column; /* works with row or column */
  flex: 1 1 auto;
  flex-direction: column;
}

.button-large {
  font-size: 120%;
}

.button-xsmall {
  padding: 0.1em 0.5em 0.2em 0.5em;
}

.link-button {
  border: none;
  box-shadow: none;
  background: none;
  color: blue;
  text-align: left;
  border-radius: 0;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.link-button:hover {
  color: blue;
  background-color: transparent;
  background-image: none;
  opacity: 0.5;
}

.transparent-button {
  background-color: transparent;
  border: none;
  padding-right: 0px;
}

.eap-grey-form input[type=text], .eap-grey-form input[type=password], .eap-grey-form select {
  background-color: #FFFFFF !important;
}
.eap-grey-form option {
  background-color: #FFFFFF;
}

.no-bullets {
  list-style-type: none;
}

/*** Default Layout ***/
html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column; /* works with row or column */
  flex-direction: column;
  min-height: 100vh;
}

/*** Pure CSS Overrides ***/
.font-serif {
  font-family: "Roboto Slab", serif !important;
}

html, button, input, select, textarea, .pure-g, .pure-g [class*=pure-u] {
  font-family: "Roboto", sans-serif;
}

.pure-form select:focus {
  border-color: #8794CB !important;
}

/*
.pure-form input[type=text], input[type=password] {
  border-radius: 0 !important;
  border: 0px none !important;
}
*/
/*** Screen Reader Stuff ***/
.screen-reader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
