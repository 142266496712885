.admin-icon-color {
  color: #385CAD;
}
.admin-icon-color:hover {
  color: #8794CB;
}

.admin-transparent-button {
  background-color: transparent;
  border: none;
  margin: 2px;
  padding: 1px;
}

.admin-transparent-button:focus {
  outline: 0.1em dotted;
}

.admin-transparent-button:active {
  outline-style: solid;
  outline-width: 2px;
}
