.examinee-invite-cancel-btn {
  margin-right: 1em;
  float: right;
}

.examinee-invite-col-2 {
  padding-top: 1.5em;
  padding-left: 1em;
}
.examinee-invite-col-2 button {
  margin-top: 0.2em;
}
