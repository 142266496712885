.eap-table .filter-button {
  border: none;
  background-color: #EAEAEA;
}
.eap-table table {
  width: 100%;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
}
.eap-table thead {
  background-color: #385CAD;
  font-size: 1.2em;
  color: #FFFFFF;
}
.eap-table thead th {
  font-weight: normal;
  text-align: left;
}
.eap-table thead .text-header {
  padding: 5px;
}
.eap-table thead .filter-container {
  padding: 0.5em;
}
.eap-table thead input {
  text-indent: 0.1em;
  color: black;
  font-weight: normal;
  font-size: 10pt;
  border: none;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  width: 100%;
  padding-right: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.eap-table thead #permissionLabel {
  color: black;
  font-weight: normal;
  font-size: 10pt;
  margin: 0px !important;
  height: 1.95em;
}
.eap-table thead .permission-selector {
  margin: 0px !important;
  color: black;
  font-weight: normal;
}
.eap-table thead .resizer {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 2px solid #EAEAEA;
}
.eap-table tbody {
  background-color: #FFFFFF;
}
.eap-table tbody td {
  padding: 7px 5px;
  border-right: 1px solid #EAEAEA;
  overflow: hidden;
  min-height: 2.6em;
}
.eap-table tbody tr {
  border-bottom: 1px solid #EAEAEA;
}
.eap-table .eap-table-footer {
  padding-top: 0.5em;
}
.eap-table .eap-table-footer button {
  border: none;
  background-color: #EAEAEA;
}
.eap-table .eap-table-footer input {
  width: 2em;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.eap-table .eap-table-footer .eap-table-page-display {
  display: inline-block;
  float: right;
}
.eap-table .centerTable {
  text-align: center;
}
