.user-selector-inputs {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}

.user-selector-inputs label input {
  margin-right: 0.5rem;
}

.user-selector-inputs label {
  margin: 0rem 1rem 1rem 0rem;
}

.userSelector__suggestions-container {
  overflow-y: scroll;
  max-height: 30rem;
  width: 100%;
  max-width: 100%;
  margin: auto;
}
.userSelector__suggestions-container ul {
  padding: 0em;
  margin: 0em;
  list-style-type: none;
}

.userSelector__suggestion--highlighted {
  background-color: #EAEAEA;
  color: #273043;
  font-weight: 700;
}

.userSelector__suggestion {
  padding: 0.35rem;
  border-bottom: 1px #273043 solid;
}
.userSelector__suggestion:nth-child(odd) {
  background-color: #FFFFFF;
}
