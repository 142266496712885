.eap-btn-primary--normal {
  width: 10em;
}

.eap-btn-primary--large {
  width: 14em;
}

.eap-btn-primary, .eap-button-primary, .pure-button-primary {
  text-align: center;
  background-color: #385CAD;
  color: #FFFFFF;
  border-radius: 4px;
}
.eap-btn-primary:hover, .eap-button-primary:hover, .pure-button-primary:hover {
  background-color: #273043;
}
.eap-btn-primary:focus > div:first-child, .eap-button-primary:focus > div:first-child, .pure-button-primary:focus > div:first-child {
  outline: solid 1px #273043;
  outline-style: dotted;
}

.eap-btn-secondary, .eap-button-secondary {
  background-color: #8E98A7;
  color: #FFFFFF;
  border-radius: 3px;
}
.eap-btn-secondary:hover, .eap-button-secondary:hover {
  background-color: #273043;
}
.eap-btn-secondary:focus > div:first-child, .eap-button-secondary:focus > div:first-child {
  outline: solid 1px #273043;
  outline-style: dotted;
}
