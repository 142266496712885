.rt-thead {
  background-color: #273043;
}
.rt-thead span {
  color: #FFFFFF;
}
.rt-thead a {
  color: #FFFFFF;
  text-decoration: none;
}

div.-sort-asc {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
}

div.-sort-desc {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
}

.side-menu-wrapper {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
