.injected-form {
  padding: 0rem;
  width: 100%;
  border: 1px solid;
}

.custom-checkout-input {
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  background-color: white;
  width: 100%;
  border-radius: 5px;
  border: solid 1px #BCC2CB;
}

.StripeElement--complete {
  font-size: bold;
  color: lightgreen !important;
}
