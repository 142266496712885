.organization-link-wrapper {
  padding-bottom: 1rem;
}

.user-display-form {
  width: 70%;

  & > div {
    margin: .5rem 0rem;
    display: flex;
    label {
      justify-content: right;
      flex: 1;
    }
    input, select {
      background-color: #EAEAEA;
      border: 1px dotted; 
      border-radius: 5px;
      padding: .5rem;
    }

    & > button {
      align-items: right;
    }

    & > * {
      flex: 3;
    }
  }
}

.user-status-display > div > label {
  padding-left: .5rem;
}
.super-reset-password, 
.super-user-edit-button-bar > button {
  margin: 1rem;
}
